.section {
  background-color: $white;
  width: 100%;
  height: auto;
  padding: 4rem 0;
  position: relative;
  overflow: hidden;
  display: flex;
  flex: 1 0 auto;

  @include rwd-md {
    padding: 8rem 0;
  }

  h2, .section-title {
    margin: 0 0 1.5rem;
  }
}

.navbar + .section {
  padding-top: 14.25rem;
}

.section__100vh {
  align-items: center;
  height: 100vh;

  @include rwd-md {
    align-items: center;
    justify-content: center;
  }
}

.section__display {
  p {
    font-family: $font-primary;
    font-size: 2.5rem;
    font-weight: 500;
    line-height: 1.1;
    margin: 0;

    @include rwd-md {
      font-size: 3.5rem;
    }
  }
}

.section__whitesmoke {
    background-color: $whitesmoke;
}

.section__whitesmoke + .section__whitesmoke {
    border-top: 2px solid rgba($black, .1);
}

.section__brand-blue {
    background-color: $brand-primary;
}

.section__accent {
    background-color: rgba($brand-secondary, .25);
}

.section__border {
    border-top: 2px solid rgba($black, .1);
}

.section__bg-img {
    background: transparent;
    background-size: cover;
    background-position: center;
    position: relative;
    overflow: hidden;
    color: $white;
    padding: 8rem 0;
    image-fit: fill;
    object-fit: cover;

    @include rwd-md {
      padding: 16rem 0;
    }

    & > * {
        z-index: 1;
    }

    &:after {
        background: rgba($brand, .5);
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 0;
    }

    h2, .section-title {
      color: $white;
    }
}

.section__bg-img-content {
    position: relative;
    z-index: 1;
}

.section__bg-fixed {
    background-attachment: fixed;
}

.section__centered {
  text-align: center;
}

.section__bigger {
  h2, .section-title {
    font-size: 2rem;
    line-height: 2.5rem;
    margin: 0 0 1rem;

    @include rwd-md {
      font-size: 3rem;
      line-height: 3.5rem;
      margin: 0 0 1.5rem;
    }
  }
}
