.primary-nav {
    text-transform: uppercase;
    font-family: $font-primary;
    font-weight: 500;
    font-size: 1rem;
    width: 100%;
    padding: 2rem 0;
}

.dark-nav .nav-link {
    color: $grey;

    &:hover, &.active {
        color: $brand;
    }
}

.light-nav .nav-link {
    color: rgba($white, .5);

    &:hover, &.active {
        color: $white!important;
    }
}

.nav-pills .nav-link {
    padding: .5rem 0;
    border-radius: 0;
    line-height: 1.25rem;
    position: relative;
    transition: color .25s ease;
    white-space: nowrap;

    @include rwd-lg {
        &:hover, &.active {
            &:after {
                width: 100%;
                opacity: 1;
                transition-duration: .15s;
            }
        }

        &:after {
            background-color: $brand-secondary;
            opacity: 0;
            content: "";
            position: absolute;
            width: 0;
            height: .25rem;
            left: 50%;
            @include vendor(transform, translateX(-50%));
            bottom: -.25rem;
            transition: all .25s ease;
        }
    }

    &.call-to-action {
        border-radius: .125rem;
        padding: .5rem 1rem .4rem!important;
        margin: 0;
        color: $white;

        &:hover {
            color: $white;
        }

        &:after {
            display: none;
        }
    }
}

.navbar {
    padding: 0;
}

.nav-pills .nav-link.active, .nav-pills .nav-link.active:focus, .nav-pills .nav-link.active:hover, .nav-pills .nav-item.open .nav-link, .nav-pills .nav-item.open .nav-link:focus, .nav-pills .nav-item.open .nav-link:hover {
    background: none;
    color: $brand;
    position: relative;
}

.header-nav .nav-item + .nav-item {
    margin-left: 0!important;

    @include rwd-lg {
        margin-left: 1.5rem!important;
    }
}

.dark-nav .nav-item + .nav-item,
.light-nav .nav-item + .nav-item {
    margin-left: 1.5rem;
}

.navbar-toggler {
    font-size: 1rem;
    line-height: 1rem;
    align-self: center;
    margin: 0;
}

.navbar-toggler-icon {
    width: 1.25rem;
    height: 3px;
    margin: 0 .5rem 0 0;
    display: inline-block;
    position: relative;
    background: $white;
    line-height: 1;
    vertical-align: middle;

    &:before, &:after {
        content: "";
        position: absolute;
        background: $white;
        width: 100%;
        height: 3px;
        left: 0;
        right: 0;
    }

    &:before {
        top: -.4rem;
    }

    &:after {
        bottom: -.4rem;
    }
}

.navbar-brand {
    width: 100%;
    display: inline-flex;
    align-items: center;
    padding: 0;
    margin-right: 0;
    justify-content: space-between;

    @include rwd-lg {
        width: auto;
        padding: 0;
        margin-right: 1rem;
    }
}

.navbar-collapse {
    flex-direction: column;
}

.collapse, .collapsing {
    @media (max-width: $screen-lg) {
        background: $whitesmoke;
        padding: 2rem;
        box-shadow: 0 0 4px rgba($black, .25);
        @include vendor-property(transition, height .5s ease);
    }
}

.collapsing {
    height: 0;
    overflow: hidden;
}

.collapse {
    height: auto;
}

.collapse.show {
    @media (max-width: $screen-lg) {
        height: auto;
        overflow: auto;
    }
}

.collapsing .primary-nav,
.collapse .primary-nav {
    padding: 0;
}

.collapsing .nav-item,
.collapse .nav-item {
    @media (max-width: $screen-lg) {
        margin: .5rem 0;
    }
}

.navbar-toggleable-md .navbar-nav .nav-link {
    @include rwd-md {
        padding-left: 0;
        padding-right: 0;
    }
}
