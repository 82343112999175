// // Fonts

//     @font-face {
//         font-family: 'Nexa';
//         src: url( #{$font-path + 'Nexa-Light.eot'}); /* IE9 Compat Modes */
//         src: url( #{$font-path + 'Nexa-Light.eot?#iefix'}) format('embedded-opentype'), /* IE6-IE8 */
//         url( #{$font-path + 'Nexa-Light.woff'}) format('woff'), /* Modern Browsers */
//         url( #{$font-path + 'Nexa-Light.ttf'})  format('truetype'), /* Safari, Android, iOS */
//         url( #{$font-path + 'Nexa-Light.svg#14cfc794995ce651ee6fc06de48ff027'}) format('svg'); /* Legacy iOS */

//         font-style:   normal;
//         font-weight:  400;
//     }

//     @font-face {
//         font-family: 'Nexa';
//         src: url( #{$font-path + 'Nexa-Bold.eot'}); /* IE9 Compat Modes */
//         src: url( #{$font-path + 'Nexa-Bold.eot?#iefix'}) format('embedded-opentype'), /* IE6-IE8 */
//         url( #{$font-path + 'Nexa-Bold.woff'}) format('woff'), /* Modern Browsers */
//         url( #{$font-path + 'Nexa-Bold.ttf'})  format('truetype'), /* Safari, Android, iOS */
//         url( #{$font-path + 'Nexa-Bold.svg#14cfc794995ce651ee6fc06de48ff027'}) format('svg'); /* Legacy iOS */

//         font-style:   normal;
//         font-weight:  600;
//     }

@import url(https://fonts.googleapis.com/css?family=Material+Icons);
@import url(https://fonts.googleapis.com/css?family=Montserrat:300,400,500,700,900|Source+Sans+Pro:400,700);
