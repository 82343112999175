.hero-heading {
  font-size: 2.5rem;
  line-height: 3rem;
  color: $white;

  @include rwd-md {
    font-size: 5rem;
    line-height: 5.5rem;
  }
}

.hero-p {
  font-size: 1.5rem;
  line-height: 2rem;
  color: rgba($white, .85);

  @include rwd-md {
    font-size: 2rem;
    line-height: 2.25rem;
  }
}

.hero-actions {
  padding: 1.5rem 0 0;
}
