
.advantage {
  padding-left: .5rem!important;
  padding-right: .5rem!important;
}

.advantages-list {
    margin: 0 0 2rem;

    li {
      margin: .5rem 0;
    }
}

.list-title-accent {
    font-family: $font-secondary;
    font-weight: bold;
    color: $brand-secondary;
    margin: 2rem 0 1rem;
    font-size: 2rem;

    .link-more {
        font-size: 1rem;
        font-weight: normal;
        color: $grey-medium;
    }
}

.logo-preview-container {
    background-color: rgba($black, .05);
    width: 100%;
    height: 8rem;
    margin: 0 auto 1rem;
    padding: 1.5rem 1rem;
    position: relative;
    display: block;

    &:hover {
      background-color: rgba($black, .075);
      text-decoration: none;
    }

    .advantage-title {
      margin: 0 0 .5rem;
      color: $brand-primary;
    }

    .advantage-subtitle {
      margin: 0;
      color: $grey-medium;
    }
}

.logo-preview {
    max-width: 80%;
    max-height: 4rem;
    position: absolute;
    top: 50%;
    left: 50%;
    @include vendor(transform, translate(-50%, -50%));
}
