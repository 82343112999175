.testimonials {
    margin: 2rem 0;
}

.testimonial-container {
    position: relative;
    display: flex;
    align-items: center;

    &:before {
        content: "";
        background: $white;
        position: absolute;
        top: 3rem;
        left: .5rem;
        right: .5rem;
        bottom: .75rem;
        box-shadow: 0 0 0 2px $whitesmoke;
    }
}

.testimonial-content {
    background: transparent;
    padding: 9rem 2rem 1.5rem;
    border: none;
}

.avatar-container {
    position: absolute;
    top: 0;
    width: 100%;
    text-align: center;

    .avatar {
        box-shadow: 0 0 4px rgba($black, .25);
        position: relative;
        z-index: 0;
    }

    &:after {
        content: "format_quote";
        position: absolute;
        z-index: 1;
        font-family: "Material Icons";
        font-size: 6rem;
        top: 2rem;
        left: 50%;
        color: $brand-secondary;
        opacity: .5;
        transform: translateX(-50%);
    }
}

.testimonial-details {
    display: inline-block;
}

.testimonial-author {
    margin: 0 0 .25rem;
}

.testimonial-position {
    color: $brand-secondary;
}


.section-accent .testimonial-container:before {
    box-shadow: 0 0 0 2px $brand-secondary;
}

.section-bg-img .testimonial-container:before {
    box-shadow: 0 0 4px rgba($black, .25);
}