// Flexboxes

$enable-flex: 									true;

// Rwd.

	$screen-xs: 									0;
	$screen-sm: 									576px;
	$screen-md: 									768px;
	$screen-lg: 									992px;
	$screen-xl: 									1200px;

	$container-sm:              	540px; // 320px
	$container-md:            		720px; // 640px
	$container-lg:             		960px; // 960px
	$container-xl:            	  1140px; // 1280px


// Font.
	$font-primary: 								"Montserrat", sans-serif;
	$font-secondary:							"Source Sans Pro", sans-serif;
	$font-icons:									"Material Icons";
	$font-path:										'../fonts/';


// Color.

	// Base
	$black:												#000000;
	$white:												#FFFFFF;
	$brand:												#202543;
	$brand-secondary:							#00ACB4;
	$brand-blue:							    #377AF4;
	$whitesmoke: 									#F5F5F5;
	$grey-medium: 								#777777;
	$grey-dark: 									#444444;
	$grey-text:										$grey-dark;


	// Dark backgrounds
	$primary-text-dark:						$white;
	$secondary-text-dark:					lighten($white, 25%);
	$disabled-hint-text-dark:			lighten($white, 50%);
	$dividers-dark:								lighten($white, 85%);

	// Light backgrounds
	$primary-text-light:					lighten($black, 25%);
	$secondary-text-light:				lighten($black, 50%);
	$disabled-hint-text-light: 		lighten($black, 75%);
	$dividers-light:							lighten($black, 85%);

	// Default 500 palette
	$red:													#FF4747;
	$pink:												#FF4799;
	$purple:											#BB47FF;
	$deep-purple:									#7947FF;
	$indigo:											#4752FF;
	$blue:												#4793FF;
	$light-blue:									#47CAFF;
	$cyan:												#47E7FF;
	$teal:												#009BAE;
	$green:												#4EC36E;
	$light-green:									#7AC34E;
	$lime:												#BBC235;
	$yellow:											#FFE847;
	$amber:												#FFCF47;
	$orange:											#FFAE47;
	$deep-orange:									#FF8247;
	$brown:												#A09090;
	$deep-brown:									#695050;
	$grey:												#9E9E9E;
	$blue-grey:										#9EB0C3;

	// Social medias
	$facebook:										#395b98;
	$twitter:											#26abe4;
	$google:											#D84B37;

// Other
$border-color: rgba($black, .1);
$box-shadow-color: rgba($black, .04);
