.contact-container {
    position: relative;
}

.contact-content {
    background: $white;
    padding: 2rem 1rem;
    text-align: center;
    position: relative;
    z-index: 1;
    box-shadow: 0 0 4px rgba($black, .25);

    @include rwd-sm {
        padding: 6rem 3rem;
    }
}

.letter {
    position: absolute;
    background: rgba($white, .9);
    width: 100%;
    height: 100%;
    box-shadow: 0 0 4px rgba($black, .15);
    overflow: hidden;

    &:before, &:after {
        content: "";
        width: 0;
        height: 0;
        position: absolute;
        border-left: 320px solid transparent;
        border-right: 320px solid transparent;
        border-bottom: 30px solid transparent;
    }

    &:before {
        border-top: 230px solid $white;
    }

    &:after {
        border-top: 240px solid rgba($black, .1);
        z-index: -1;
    }
}

.letter-left {
    left: -64px;
    top: -32px;
    transform: rotate(-4deg);
}

.letter-right {
    right: -64px;
    bottom: -32px;
    transform: rotate(2deg);
}