.contact-card-list {

}

.contact-card {
  background-color: $white;
  border: 1px solid $border-color;
  box-shadow: 0 .125rem .25rem $box-shadow-color;
  padding: 1.5rem 1rem;
  margin: 0 0 1rem;
  font-size: 1.5rem;
}

.contact-card--link-icon {
  font-weight: normal;
  position: relative;
  overflow: hidden;
  text-decoration: none;
  display: inline-flex;

  &:before {
    content: "link";
    font-family: $font-icons;
    font-size: 1.25rem;
    line-height: 1.5rem;
    width: 1.5rem;
    height: 1.5rem;
    display: inline-block;
    color: $grey-medium;
    margin: 0 0 0 .25rem;
    transform: rotate(-45deg);
  }

  &:hover {
    text-decoration: none;

    &:before {
      color: $brand-secondary;
    }

    span {
      opacity: 1;
    }
  }

  span {
    opacity: 0;
    font-size: 1rem;
    line-height: 1.25rem;
    color: $grey-medium;
    vertical-align: bottom;
    transition: opacity .25s;
    margin: .2rem 0 0;
  }
}

.contact-card--name {
  font-weight: bold;
  line-height: 2rem;
}

.contact-card--infos {
  font-size: 1.25rem;
  list-style: none;
  margin: 0;
  padding: 0;

  li {
    line-height: 1.5rem;
    margin: .125rem 0 0;
  }
}
