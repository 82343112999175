* {
	box-sizing: border-box;
}

body {
    background: $white;
    font-family: $font-secondary;
    letter-spacing: 0;
    font-weight: 400;
    font-style: normal;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -moz-font-feature-settings: "liga" on;
}

article, aside, details, figcaption, figure, footer, header, hgroup, main, menu, nav, section, summary {
    position: relative;
}

a,
area,
button,
.btn,
.button,
[role="button"],
input,
label,
select,
summary,
textarea {
    &:hover, &:active, &:focus {
        outline: none;
    }
}

a {
    cursor: pointer;
}

h1, h2, h3, h4, h5, h6 {
    color: $brand;
    font-family: $font-primary;
    // margin: 0 0 1rem;

    a {
        color: inherit;

        &:hover {
            color: inherit;
        }
    }
}

h1 { font-size: 3rem; line-height: 3rem }
h2 { font-size: 2rem; line-height: 2rem }
h3 { font-size: 1.6rem; line-height: 1.6rem }
h4 { font-size: 1.4rem; line-height: 1.4rem }
h5 { font-size: 1.2rem; line-height: 1.2rem }
h6 { font-size: 1rem; line-height: 1rem }

button, input, optgroup, select, textarea {
    color: inherit;
    font-family: inherit;
    margin: 0;
}

// p, p.small {
//     font-size: 1rem;
//     line-height: 1.25rem;
//     margin: 0 0 .75rem;
// }

.display-1, .display-2, .display-3, .display-4, .display-5, .display-6 {
    font-family: $font-primary;
		font-weight: 500;
}
