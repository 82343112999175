.creators-title {
  font-size: .75rem;
  color: $grey;
  display: inline-block;
  float: left;
  margin: 0 1rem;
  width: auto;
  overflow: hidden;
}

.creator-logo {
  height: 48px;
  display: inline-block;
  margin: 0;
  float: left;
  opacity: .75;

  &:hover {
    opacity: 1;
  }
}

.creators-list {
  list-style: none;
  display: flex;
  flex-direction: row;
  margin: 0;
  padding: 0;

  li {
    display: flex;
    margin: 0;

    &:not(:last-child) {
      margin: 0 1rem 0 0;
    }
  }
}
