.card-columns.mentors {
    column-count: 4;
}

.card {
    border-radius: 0;
}

.card.mentor {
    border: none;
}

.card.startup {
    border: none;
    margin-bottom: 1.25rem;

    .card-block {
        border: 1px solid rgba($black, .125);
        border-top: none;
    }
}

.card-startup-img {
    background: $whitesmoke;
    width: 114px;
    height: 114px;
    overflow: hidden;
    position: relative;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    margin: .25rem;
    float: left;

    &:after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border: 2px solid rgba($white, .5);
    }
}

.card-startup-top {
    background-color: $brand;
    width: 100%;
    min-height: 122px;
    color: $white;
    padding: 0;
    overflow: hidden;
}

.card-startup-infos {
    padding: 1rem 1rem 1rem 8.625rem;
}

.card-startup-name {
    display: inline-block;
    text-transform: none;
    font-size: 1.5rem;
    line-height: 1.5rem;
    color: $brand-secondary;
    margin: 0;
    text-transform: uppercase;
    margin: 0 .5rem 0 0;
}

.card-startup-program {
    color: rgba($white, .25);
    font-family: $font-primary;
    font-size: 16px;
    display: inline;
    text-transform: initial;
    white-space: nowrap;
}

.card-startup-punchline {
    font-size: 1rem;
    margin: 0 0 .25rem;
    font-family: $font-secondary;
    line-height: 1.25rem;
}

.card-links {
    list-style: none;
    padding: 0;
    margin: 0;
    font-size: .875rem;

    li {
        position: relative;
        padding-left: 1.375rem;
        display: inline-block;
        margin-right: .5rem;

        &:before {
            position: absolute;
            content: 'link';
            font-family: Material Icons;
            font-size: 1.125rem;
            color: $white;
            // transform: rotate(-45deg);
            left: 0;
            top: -.15rem;
        }
    }
}

.card-links a {
    color: $brand-secondary;
}

.card-text > p {
    font-size: 1rem!important;
    line-height: 1.25rem!important;
    margin: 0 0 1rem!important;
    font-family: $font-secondary;
}

.card-startup-team {
    margin-top: 1rem;
    background: $whitesmoke;
}

.card-startup-team-label {
    display: inline-block;
    padding: .5rem 1rem;
    text-transform: uppercase;
    font-weight: bold;
    color: $grey;
}

.card-startup-team-members {
    display: inline-table;
    padding: .5rem 1rem;
    margin: 0;
    list-style: none;
    color: $grey-medium;
    border-left: 1px solid $white;

    & > *:not(:last-child) {
        margin-bottom: .25rem;
    }
}