.numbers {
  list-style: none;
  display: flex;
  flex-direction: column;
}

.number-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin: 0 0 1rem;

  @include rwd-md {
    flex-direction: row;
  }

  &.vertical {
    flex-direction: column;
    align-items: flex-start;
  }
}

.number {
  min-width: 100%;
  font-family: $font-primary;
  color: $brand-secondary;
  font-weight: bold;
  font-size: 4rem;
  line-height: 5rem;
  text-align: center;
  flex-shrink: 0;

  @include rwd-md {
    min-width: 50%;
    text-align: right;
  }
}

.number-meta {
  width: 100%;
  font-size: 1.75rem;
  line-height: 2rem;
  color: $grey;
  text-align: center;

  @include rwd-md {
    width: auto;
    text-align: left;
  }
}

.number + .number-meta {
  margin: 0 0 .5rem 1rem;
}

.vertical {
  .number, .number-meta {
    text-align: left;
    margin: 0;
  }
}
