.keynumber-list {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
}

.keynumber {
  display: inline-flex;
  align-items: center;

  .value {
    font-family: $font-primary;
    font-weight: bold;
    font-size: 4rem;
    line-height: 5rem;
    text-align: right;
    color: $brand-secondary;
    letter-spacing: -4;
  }

  .value-desc {
    padding-left: 1rem;
    font-size: 1.75rem;
    line-height: 2rem;
    color: $grey-medium;
  }
}
