.person {
    width: 100%;
    height: auto;
    background: $brand;
    color: $white;
}

.person-img {
    position: relative;
    overflow: hidden;

    img {
        width: 100%;
        max-height: 300px;
    }
}

.person-block {
    padding: 1.25rem;
    text-align: center;

    h2 {
        font-size: 1.25rem;
        color: $brand-secondary;
        margin: 0 0 .25rem;
    }

    .position:not(:last-child) {
        font-size: 1rem;
        margin: 0 0 1rem;
    }
}