.team {
  margin-top: 2rem;
}

.team-member {
  position: relative;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.team-member-img {
  width: 50%;
  border-radius: 50%;

  @include rwd-md {
    width: 100%;
  }
}

.team-member-block {
  padding: 1rem 0;
  display: inline-block;
  text-align: center;
  margin: 0 auto;
}

.team-member-name {
  color: $brand-primary;
  font-family: $font-primary;
  margin: 0 0 .25rem;
}

.team-member-position {
  font-size: 1rem;
  margin: 0;
  color: $grey-medium;
}
