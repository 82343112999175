.starter-logo {
    width: 120px;
    height: 60px;
    padding: 1rem 0;
    display: flex;
    align-items: center;
    margin: 0 1rem 0 0;
    flex-shrink: 0;

    @include rwd-sm {
        width: 240px;
        height: 116px;
        padding: 0 2rem;
        box-shadow: 0 0 4px rgba($black,.25);
    }

    h1 {
        @include hide-text;
        margin: 0;
    }

    img {
      width: auto;
      max-height: 80px;
      margin: 0 auto;
    }
}

.starter-logo.header-logo {
    background: $brand;

    @include rwd-sm {
        margin-bottom: -1rem;
    }

    &:hover {
        background: darken($brand, 5%);
    }
}

.starter-logo.footer-logo {
    background: $white;
    margin-top: -1rem;
}
