.kn-form-group {

}

.kn-content .kn-form li.kn-form-col {
    width: 100%;
}

.kn-input label {
    font-size: 1.25rem;
    color: $brand!important;
    display: inline-block;
}

.kn-required {
    color: $brand-secondary!important;
}

.kn-input input[type=email], 
.kn-input input[type=password], 
.kn-input input[type=text], 
.kn-input input[type=url], 
.kn-input select {
    min-width: 50%;
}

.kn-input input[type=email], 
.kn-input input[type=password], 
.kn-input input[type=text], 
.kn-input input[type=url], 
.kn-input select, 
.kn-input textarea, 
.kn-map-search input[name=map_origin], 
.kn-map-search select, 
.kn-search-filter input[type=text], 
.kn-search-filter select {
    line-height: 1.5em!important;
    font-size: 1.25em!important;
    padding: .5rem 1rem!important;
    border: 2px solid #DDDDDD!important;
    border-radius: 0!important;

    &:focus {
        border-color: $brand-secondary!important;
    }
}

.kn-input select,
.kn-map-search select,
.kn-search-filter select {
    background: url(../images/ic_arrow_drop_down_black_24dp_1x.png) #EEEEEE no-repeat right center!important;
    -webkit-appearance: none;
    padding: 0 1rem!important;
    position: relative;

    &:after {
        content: "arrow_drop_down";
        position: absolute;
        font-family: "Material Icons";
    }
}

.kn-input textarea {
    height: 150px!important;
    width: 100%!important;
}

.kn-content p.kn-instructions {
    font-style: initial!important;
    font-size: 1rem!important;
    margin: 0 0 1rem!important;
}

.kn-view input[type=submit], #kn-app-menu ul {
    border-radius: 0!important;
    padding: .5rem 1rem!important;
    text-transform: uppercase;
    font-size: 1rem!important;
}