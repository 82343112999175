/**
* Description: This file import all the CSS files that needed in all pages of the app.
* Version: 1.0.0
* Last update: 2016/07/15
* Author: Alexandre Jolly <hello@alexandrejolly.com>
*
* Summary:
*
*    1. SETTINGS
*    2. ASSETS
*    3. BASE
*    4. MODULES
*    5. LAYOUTS
*    6. HELPERS
*    7. THEMES
*
*/

@import "settings/variables";
@import "settings/mixins";

/* ==========================================================================
1. SETTINGS
========================================================================== */
$enable-flex: true;
@import "settings/bootstrap-variables";
@import "../../../node_modules/bootstrap/scss/bootstrap";

/* ==========================================================================
2. ASSETS
========================================================================== */
@import "assets/fonts";

/* ==========================================================================
3. BASE
========================================================================== */
@import "base/base";

/* ==========================================================================
4. MODULES
========================================================================== */
@import "modules/advantages";
@import "modules/avatar";
@import "modules/btn";
@import "modules/card";
@import "modules/contact-card";
@import "modules/contact";
@import "modules/creator";
@import "modules/forms";
@import "modules/hero";
@import "modules/keynumber";
@import "modules/knack";
@import "modules/list";
@import "modules/logo";
@import "modules/nav";
@import "modules/numbers";
@import "modules/partner";
@import "modules/person";
@import "modules/team";
@import "modules/testimonials";
@import "modules/spinner";

/* ==========================================================================
5. LAYOUTS
========================================================================== */
@import "layouts/candidature";
@import "layouts/footer";
@import "layouts/header";
@import "layouts/page";
@import "layouts/section";
@import "layouts/pageupdate";

/* ==========================================================================
6. HELPERS
========================================================================== */
@import "helpers/base";
@import "helpers/clearfix";
@import "helpers/opacity";

/* ==========================================================================
7. THEMES
========================================================================== */
@import "themes/default";
