label, legend {
  font-weight: bold;
  color: $grey-dark;
  margin-bottom: .125rem;
  font-size: 1rem;
}

.form-title {
  margin-top: 2rem;
}

.form-label-list {
  padding-left: 1rem;
  color: $grey-medium;
}

.form-team-members {
  padding: 0;
  margin: 0;
  list-style: none;
}

.form-team-member {
  position: relative;
}

.form-remove-member {
  position: absolute;
  right: -1.5rem;
  bottom: 1rem;
  cursor: pointer;
  line-height: 2.375rem;
  font-size: 1.25rem;
  color: $grey;

  &:hover {
    color: $red;
  }
}

.form-new-member {
  display: inline-flex;

  &:hover {
    text-decoration: none;
  }

  .icon {
    font-size: inherit;
    line-height: inherit;
    color: inherit;
    margin: 0 .25rem 0 0;
  }

  span {
    &:hover {
      text-decoration: underline;
    }
  }
}
