.header {
    background: $whitesmoke;
    width: 100%;
    height: auto;
    position: relative;
    display: flex;
    padding: 0!important;
}

.header-top {
    background: $brand;
    height: 60px;
    box-shadow: 0 0 4px rgba($black,.25);
    z-index: 50!important;

    @include rwd-sm {
        background: $white;
        height: 100px;
    }

    .container {
        justify-content: space-between;
    }
}

.header.section-bg-img:after {
    background-color: rgba($brand, .15);
}

.header-container {
    @media (max-width: $screen-sm) {
        width: 100%;
    }
}

.header-content {
    width: 100%;
    height: auto;
    min-height: 12rem;
    margin-top: 60px;
    display: flex;
    align-items: center;
    padding: 2rem 0;

    @include rwd-sm {
        margin-top: 100px;
    }
}

.header-card {
    background: $white;
    color: rgba($black, .75);
    font-size: 2rem;
    text-align: center;
    padding: 2rem 1rem;
    margin: 2rem 0;
    box-shadow: 0 0 4px rgba($black, .25);

    @include rwd-sm {
        padding: 3rem 4rem;
    }

    p {
      font-size: 1.5rem;
      line-height: 2rem;
      margin: 0 0 2rem;
    }
}

.header-card-accent {
    background: $brand-secondary;

    @include rwd-sm {
        padding: 1.5rem 2rem;
    }

    p {
        font-size: 1.25rem;
        line-height: 1.5rem;
        margin: 0 0 1rem;
    }
}

.header-jumbotron {
    background-color: rgba($white, .9);
    position: relative;

    &:before, &:after {
        content: "";
        position: absolute;
        z-index: -1;
        top: 50%;
        left: 50%;
        @include vendor(transform, translate(-50%,-50%));
        box-shadow: 0 0 4px rgba($black, .15);
    }

    &:before {
        background-color: rgba($white, .5);
        width: calc(100% - 4rem);
        height: calc(100% + 1rem);
    }

    &:after {
        background-color: rgba($white, .25);
        width: calc(100% - 8rem);
        height: calc(100% + 2rem);
    }
}

.header-page-title {
    font-size: 2.5rem;
    line-height: 1.1;
    text-align: center;

    @include rwd-md {
      font-size: 4rem;
    }
}

.navbar-toggleable-md > .container {
    padding-right: 1rem!important;
    padding-left: 1rem!important;
}
