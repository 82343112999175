.footer {
  padding: 1.5rem 0;

  .container {
    display: flex;
    align-items: center;
    flex-direction: column;
  }
}

.footer-copy {
  font-family: $font-secondary;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.5rem;
  color: rgba($grey-medium, .25);
  margin: 1rem 0 0;
}

.footer-img {
  width: 150px;
  height: auto;
}
