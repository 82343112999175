.btn {
    border-radius: .125rem;
    font-family: $font-primary;
    font-weight: 500;
    line-height: 1.5;
    padding: .5rem 1rem .4rem;
    white-space: initial;
    margin-top: .5rem;
}

.btn-grey {
    background: $whitesmoke;
    color: $grey!important;

    &:hover {
        background: darken($whitesmoke, 15%);
        color: $grey-medium!important;
    }
}

.btn-black-transparent {
    background: rgba($black, .1);
    color: $grey;

    &:hover {
        background: rgba($black, .2);
        color: $grey-medium;
    }
}

.btn-font-secondary {
    font-family: $font-secondary;
    font-weight: bold;
    padding: .4rem 1rem;
}

.btn-accent {
    background-color: $brand-secondary;
    color: $white;

    &:hover {
        background: darken($brand-secondary, 5%);
        color: $white;
    }
}

.btn-white {
    background: $white;
    color: $brand-primary;

    &:hover {
        background: $whitesmoke;
    }
}

.btn-sm {
    padding: .25rem .5rem;
}

.btn + .btn {
    margin-left: .5rem;
}
