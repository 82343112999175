.accent-list {
    list-style: none;
    font-size: 1.25rem;
    padding: 0 1rem;

    li {
        line-height: 1.75rem;

        &:before {
            content: "■";
            margin: 0 .75rem 0 -1rem;
            line-height: 1.75rem;
            font-size: 1.5rem;
            color: $brand-secondary;
        }
    }
}
