.avatar {
    overflow: hidden;
    margin: 0 .25rem .25rem;
    display: inline-block;

    & > img {
        width: 100%;
    }
}

.avatar-xl {
    width: 10rem;
    height: 10rem;
}

.avatar-lg {
    width: 8rem;
    height: 8rem;
}

.avatar-md {
    width: 6rem;
    height: 6rem;
}

.avatar-sm {
    width: 4rem;
    height: 4rem;
}

.avatar-xs {
    width: 2rem;
    height: 2rem;
}