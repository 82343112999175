.partners {
    margin: 2rem 0 0;
    text-align: center;
}

.partners-row {
    margin: 0;
    padding: 0;
    display: inline-block;
    list-style: none;
}

.partner-logo {
    background: $white;
    min-width: 160px;
    max-height: 160px;
    display: inline-block;
    margin: 0 .5rem 1rem;

    img {
      max-width: 100%;
    }
}
